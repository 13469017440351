<script>
import BaseForm from '@/views/widget/INK/BaseForm/BaseForm.vue'
import PageHeader from '@/views/widget/typography/PageHeader.vue'
import { VSelect, VTextField } from 'vuetify/lib/components'
import { getItalyConfigs, updateItalyConfigs } from '@/model/dataLayer/api/Italy/ItalyBills'
import { showSuccessMessage } from '@/model/utils/utils'

export default {
  components: { PageHeader, BaseForm },
  data: function () {
    return ({
      ItalyConfig: {},
      showPassword: false
    })
  },
  computed: {
    schemas () {
      return [
        {
          key: 'pivaPaese',
          name: 'P.IVA Paese',
          componentProps: {
            rules: [v => /^[A-Z]{2}$/.test(v) || '[A-Z]{2}']
          }
        },
        {
          key: 'piva',
          name: 'P.IVA',
          componentProps: {
            rules: [v => /^.{1,28}$/.test(v) || '任意字符1-28个']
          }
        },
        {
          key: 'codiceFiscale',
          name: 'Codice Fiscale',
          required: false,
          default: '',
          componentProps: {
            rules: [v => {
              if (v !== '') {
                return /^[A-Z0-9]{11,16}$/.test(v) || 'A-Z 0-9 11-16个字符'
              }
            }]
          }
        },
        {
          key: 'denominazione',
          name: 'Denominazione',
          componentProps: {
            rules: [v => /^[\x20-\xFF]{1,80}$/.test(v) || '1-80个字符']
          }
        },
        {
          key: 'regimeFiscale',
          name: 'Regime fiscale',
          component: VSelect,
          componentProps: {
            items: [
              { value: 'RF01', text: 'RF01 (Regime ordinario)' },
              { value: 'RF02', text: 'RF02 (Regime dei contribuenti minimi)' },
              { value: 'RF04', text: 'RF04 (Agricoltura e attività connesse e pesca)' },
              { value: 'RF05', text: 'RF05 (Vendita sali e tabacchi)' },
              { value: 'RF06', text: 'RF06 (Commercio dei fiammiferi)' },
              { value: 'RF07', text: 'RF07 (Editoria)' },
              { value: 'RF08', text: 'RF08 (Gestione di servizi di telefonia pubblica)' },
              { value: 'RF09', text: 'RF09 (Rivendita di documenti di trasporto pubblico e di sosta)' },
              { value: 'RF10', text: 'RF10 (Intrattenimenti, giochi e altre attività)' },
              { value: 'RF11', text: 'RF11 (Agenzie di viaggi e turismo)' },
              { value: 'RF12', text: 'RF12 (Agriturismo)' },
              { value: 'RF13', text: 'RF13 (Vendite a domicilio)' },
              { value: 'RF14', text: 'RF14 (Rivendita di beni usati, oggetti d’arte, antiquariato o da collezione)' },
              { value: 'RF15', text: 'RF15 (Agenzie di vendite all’asta di oggetti d’arte)' },
              { value: 'RF16', text: 'RF16 (IVA per cassa P.A.)' },
              { value: 'RF17', text: 'RF17 (IVA per cassa)' },
              { value: 'RF19', text: 'RF19 (Regime forfettario)' },
              { value: 'RF18', text: 'RF18 (Altro)' }
            ]
          }
        },
        {
          key: 'indirizzo',
          name: 'Indirizzo',
          componentProps: {
            rules: [v => /^[\x20-\xFF]{1,60}$/.test(v) || '1-60个字符']
          }
        },
        {
          key: 'cap',
          name: 'CAP',
          componentProps: {
            rules: [v => /^[0-9]{5}$/.test(v) || '0-9 5位数']
          }
        },
        {
          key: 'comune',
          name: 'Comune',
          componentProps: {
            rules: [v => /^[\x20-\xFF]{1,60}$/.test(v) || '1-60个字符']
          }
        },
        {
          key: 'provincia',
          name: 'Provincia',
          componentProps: {
            rules: [v => /^[A-Z]{2}$/.test(v) || '[A-Z]{2}']
          }
        },
        {
          key: 'nazione',
          name: 'Nazione',
          componentProps: {
            rules: [v => /^[A-Z]{2}$/.test(v) || '[A-Z]{2}']
          }
        },
        {
          key: 'pecDestinatario',
          name: 'Destinatario PEC',
          componentProps: {
            rules: [v => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || '请输入正确邮箱格式']
          }
        },
        {
          key: 'smtpHost',
          name: 'SMTP Host',
          required: false,
          default: '',
          componentProps: {
            rules: []
          }
        },
        {
          key: 'smtpUsername',
          name: 'SMTP UserName',
          required: false,
          default: '',
          componentProps: {
            rules: []
          }
        },
        {
          key: 'smtpPassword',
          name: 'SMTP Password',
          required: false,
          component: VTextField,
          default: '',
          componentProps: {
            rules: [],
            type: this.showPassword ? 'text' : 'password',
            iconAppend: {
              icon: this.showPassword ? 'mdi-eye' : 'mdi-eye-off',
              onClick: () => {
                this.showPassword = !this.showPassword
              }
            }
          }
        },
        {
          key: 'smtpAddress',
          name: 'SMTP Address',
          required: false,
          default: '',
          componentProps: {
            rules: []
          }
        },
        {
          key: 'smtpPort',
          name: 'SMTP Port',
          required: false,
          default: '',
          componentProps: {
            rules: []
          }
        },
        {
          key: 'smtpSecure',
          name: 'SMTP Secure',
          required: false,
          default: '',
          componentProps: {
            rules: []
          }
        }
      ]
    }
  },
  async mounted () {
    await this.reload()
  },
  methods: {
    async reload () {
      this.ItalyConfig = await getItalyConfigs()
    },
    async saveConfig (config) {
      await updateItalyConfigs(config)
      await this.reload()
      showSuccessMessage('保存成功！')
    }
  }
}
</script>

<template>
  <base-form
    :editing-object="this.ItalyConfig"
    :is-dialog="false"
    :schema="this.schemas"
    @submit="saveConfig"
  >
    <page-header>Italy Config</page-header>
  </base-form>
</template>

<style scoped>

</style>
